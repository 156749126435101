.background-video{
	position: relative;

	padding-bottom: 56.25%;

	overflow: hidden;

	pointer-events: none;
	video,iframe{
		position:absolute;
		left:50%;
		top:50%;
		width: calc(100% + 2px);
		height: calc(100% + 2px);
		transform: translate(-50%,-50%);
		pointer-events: none;
	}

}