.services-page{

	
	
	.services{
		.service{

			transition: all .5s;

			margin-bottom: 100px;

				@include size-phone{
					margin-bottom: 40px;
				}

			& > h3{
				margin-top: 42px;
				@include size-phone{
					margin-top: 30px;
					margin-bottom: 15px;
				}
			}

			cursor: pointer;


			&.no-text{
				cursor:default;
			}


			.service-image{
				padding-bottom:23.25%;
				background-size: cover;
				background-position:50% 50%;

				@include size-phone{
					padding-bottom:36%;
				}

				position:relative;

				@include blueOverlay;




			}

			.text{
					opacity: 0;
					max-height: 0px;
					overflow: hidden;
					transition: max-height 1s .5s, opacity .5s; 

				}

			&.open,&.no-text{

				cursor:default;
				.service-image{
					&:after,&:before{
						opacity: 0;
					}
				}


				.text{
					opacity: 1;
					max-height: 1000px;
					transition: max-height 1s, opacity .5s .5s; 
				}
			}
		}
	}	
}