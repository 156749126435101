




.case-study-preview{

	position:relative;

	&:nth-child(even){
		flex-flow: row-reverse;

		//border:1px solid #f00;

		@include size-tablet-portrait{
			flex-flow: column;
		}
	}


		.main-feature{

			position:relative;
			.background{
				padding-bottom: 56.25%;

				background-size:cover;
				background-position: 50% 50%;

				.background-video{
					position:absolute;
					top:0;
					left:0;
					height: 100%;
   					width: 100%;
				}
			}

			.title{
				position: absolute;
				height: 100%;
				width: 100%;
				top:0;
				left: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				z-index: 4;
			}
		}


		.thumbnails{

			position:absolute;
			width: calc(100% + #{$columDistance});
			height: calc(100% + #{$columDistance});

			display: grid;
			grid-template-rows: 50% 50%;
			grid-template-columns: 50% 50%;

			border:0px solid #f00;
			margin: -#{$columDistance / 2};

			overflow: hidden;

			@include size-tablet-portrait{
				border:0px solid #f00;
				height: 31vw;
				position:relative;	
				grid-template-rows: 100%;
				grid-template-columns: 25% 25% 25% 25%;

				margin: -#{$mobileColumnDistance / 2};
				width: calc(100% + #{$mobileColumnDistance});
			    //height: calc(100% + #{$mobileColumnDistance});

				
			}


			.thumbnail{
				position:relative;
				.thumbnail-inner{
					position:absolute;
					top:50%;
					left:50%;
					transform: translate(-50%,-50%);
					width: calc(100% - #{$columDistance});
					height: calc(100% - #{$columDistance});

					background-size:cover;
					background-position: 50% 50%;
					background-repeat: no-repeat;

					@include size-tablet-portrait{
						width: calc(100% - #{$mobileColumnDistance});
						height: calc(100% - #{$mobileColumnDistance});
					}

				}
			}
		

		}


		// blue overlay


		.background,.thumbnail-inner{
			&:after{
				opacity: 1;
				display: block;
				position:absolute;
				content:'';
				height: 100%;
				width: 100%;
				left:0;
				top:0;
				background-color: rgba(#003a70,.3);
				z-index: 3;
				transition: .5s all;

			}

			&:before{
				opacity: 1;
				display: block;
				position:absolute;
				content:'';
				height: 100%;
				width: 100%;
				left:0;
				top:0;
				z-index: 2;
				background-color: rgba(#000c39,1);
				mix-blend-mode: color;
				transition: .5s all;


			}
		}


		&:hover{
			.background,.thumbnail-inner{
				&:before,&:after{
					opacity: 0;
				}
			}
		}

	.cover-link{
		position:absolute;
		display: block;
		top:0;
		left:0;
		width: 100%;
		height: 100%;
		z-index: 5;
	}

}

// hack for safari it can't transition blend modes :-(

.browser-safari{

	.case-study-preview{

		.background,.thumbnail-inner{

			&:before{
				transition:none;
			}
		}
	}
}