

$colour-background:rgba(249,249,249,1);
$colour-background-transparent:rgba(249,249,249,0);
$colour-background-grey: rgba(115,115,125,.5);
$colour-image-background: rgba(0,16,48,1​);

$colour-text:#656571;//rgba(92,95,105,1);
$colour-text-light:rgba(251,251,251,1);
$colour-text-on-primary:#f0f0f0;//rgba(223,215,208,1);
$colour-secondary-text:rgba(92,95,105,1);
$colour-primary: #003A70; //rgba(5,24,55,1);// #003A70;//rgba(0,12,57,1);
$colour-secondary: #ed145b;//rgba(237,20,105,1);
$colour-grey:#656571;
$colour-white:#fff;



// backgrounds for the popsups

$colour-popup-background: #f5f5f5;
$colour-popup-background-transparent: rgba(245,245,245,0);

$border-width:  15px; //17px;

$global-border: $border-width solid $colour-background;

$mobile-border-width:8px;


$pageMargin: 125px;
$columDistance: 25px;
$mobileColumnDistance: 10px;

$greyLineColor:#b2b2b7;

@mixin dividingLine{
      display: block;
      position:absolute;
      content: '';
      height:3px;
      width: 50px;
      background: $colour-white;
      left:50%;
      transform:translate(-50%,-50%);
      background:$greyLineColor;
      height: 1px;

}


$desktopBreakpointLarge : 1400px;
$desktopBreakpoint : 1025px;
$tabletBig: 769px;
$tabletSmall: 481px;




@mixin blueOverlay{

  webkit-perspective:10; 
  webkit-backface-visibility:none; 
  transform: translateZ(0);
  &:after{
        opacity: 1;
        display: block;
        position:absolute;
        content:'';
        height: 100%;
        width: 100%;
        left:0;
        top:0;
        background-color: rgba(#003a70,.3);
        z-index: 3;
        transition: .5s all;


      }

    &:before{
        opacity: 1;
        display: block;
        position:absolute;
        content:'';
        height: 100%;
        width: 100%;
        left:0;
        top:0;
        z-index: 2;
        background-color: rgba(#000c39,1);
        mix-blend-mode: color;

        transition: .5s all;
        //will-change: opacity;
        //transform: translateZ(0);
     }
}



@mixin size-small-desktop {
  @media (max-width: 1400px){
    @content;
  }
}



@mixin size-tablet {
  @media (max-width: #{$desktopBreakpoint}){
    @content;
  }
}

@mixin size-tablet-portrait {
  @media (max-width: #{$tabletBig}) {
    @content;
  }
}

@mixin size-phone{
  @media (max-width: #{$tabletSmall}) {
    @content;
  }
}

@mixin size-desktop {
  @media (min-width: #{$desktopBreakpoint}) {
    @content;
  }
}

@mixin size-desktop-large {
  @media (min-width: #{$desktopBreakpointLarge}) {
    @content;
  }
}



