

$columns: 12;
$gutterWidth:25px;
$mobileGutterWidth:10px;



@function col-size($cols) {
   @return (calc(#{(100 / $columns)* $cols}% - (#{$gutterWidth})))

 //@return (calc(#{(100 / $columns)* $cols}% - (#{$gutterWidth} + #{$gutterWidth / $columns})))
};

@function col-size-negative($cols) {
   @return (calc(#{(-100 / $columns)* $cols}% - (#{$gutterWidth})))

 //@return (calc(#{(100 / $columns)* $cols}% - (#{$gutterWidth} + #{$gutterWidth / $columns})))
};

@function col-size-no-start-gutter($cols) {
 @return ((($colwidth+$gutterWidth)*$cols))
};

@function col-size-no-start-or-end-gutter($cols) {
 @return ((($colwidth+$gutterWidth)*$cols)-$gutterWidth)
};




%float-styles {
  //float: left;
  position: relative;
}

.content-wrapper{
  position:relative;

  //height: 100%;
  width: 100%;
  max-width: calc( 1600px - (#{$pageMargin} * 2));

  @media (max-width:calc( 1600px - ((#{$pageMargin} * 2) - 80px))){
    max-width: calc(100vw - 80px);
  }

  @include size-phone{
    max-width: 100%;
  }

  margin: auto;
}

.content-row{
  display: flex;
  align-items:stretch;
  flex-wrap: wrap;
  margin: auto;
  //margin-left: -18px;
  margin-left: 0;
  //margin: 0 -$gutterWidth;

  //max-width: 1600px;

  //width: calc(100% + #{$gutterWidth*2});
  width: 100%;
  padding:($gutterWidth) 0;



  @include size-tablet-portrait{
       padding:#{$mobileGutterWidth} 0;
  }

  padding-right:$gutterWidth;


  &.use-margin{
    padding-top:0;
    padding-bottom:0;
     margin-top:($gutterWidth);
     margin-bottom:($gutterWidth);
  }


  &:last-of-type{
    //margin-bottom: 0;
  }

}

//@mixin col-x-list {
  @for $i from 1 through $columns {
      .col-#{$i} {
              //width:col-size($i);
              margin-left: $gutterWidth;
              flex-basis: col-size($i);
              width: col-size($i);
              @extend %float-styles;
            }

       .col-#{$i}-and-half {
              //width:col-size($i+.5);
              flex-basis: col-size($i+.5);
              width: col-size($i+.5);
              margin-left: $gutterWidth;
              @extend %float-styles;

           

            }

      .col-#{$i}-offset {
              margin-left: calc(#{$gutterWidth*2} + #{col-size($i)}) !important;

            }
       .col-#{$i}-and-half-offset {
              margin-left: calc(#{$gutterWidth*2} + #{col-size($i+.5)}) !important;

            }
  }
//}

//@include col-x-list;



.cols{
  margin:0 calc(#{-$gutterWidth}); 
}



.grider{
  position: fixed;
  height: 100%;
  width: 100%;
  //max-width: calc( 1600px - (#{$pageMargin} * 2));
  top:0px;
  left:50%;
  transform:translateX(-50%);
  pointer-events: none;
  opacity: 1;
  z-index: 9999;
  border:0px solid #f00;

  .content-row{
    height: 100%;
    div{
        background:rgba(255,0,0,.04);
    }
  }


  .col{
    background: #f00;
    height: 100%;
    font-size: 10px;
    font-family: Arial;
  }
}



// mobile grid reset

@include size-tablet-portrait{


  .content-row{

    flex-flow: column;
    //margin: 0;
    padding-right:$mobileGutterWidth;
    padding-left:$mobileGutterWidth;
  }

  @for $i from 1 through $columns {
        .col-#{$i} {
                //width:col-size($i);


                margin-left: 0;
                flex-basis: 100%;
                 width: 100%;



                margin-bottom: $mobileGutterWidth;

                &:last-of-type{
                  margin-bottom:0;
                }
      
              }

         .col-#{$i}-and-half {
                //width:col-size($i+.5);
                 margin-left: 0;
                flex-basis: 100%;
                width: 100%;

                margin-bottom: $mobileGutterWidth;

                &:last-of-type{
                  margin-bottom:0;
                }

              }

        .col-#{$i}-offset {
                margin-left: 0 !important;

              }
         .col-#{$i}-and-half-offset {
                margin-left: 0 !important;

              }
    }


  }



