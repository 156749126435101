.scroll-arrow{
  position: fixed;
  width: 38px;
  height: 50px;
  //left:calc(50%);
  left: calc(50vw - 18px);
  //margin-left: -.5px
  //transform: translate(-9.5px, 0);
  border: 0px solid #f00;
  bottom:86px;

  @include size-tablet{
    //bottom:60px;
  };

  cursor: pointer;
  overflow: hidden;
  border:0px solid #f00;
  z-index: 99;
  .inner-arrow{
    width: 18px;
    height: 18px;
    left:9px;
    position: absolute;
    bottom:0px;
    background-image:url('/assets/global/scroll_arrow.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

}

.scroll-anchor{
  //border: 1px solid #f00;
}

.light-arrow{
  .scroll-arrow{
    .inner-arrow{
        background-image:url('/assets/global/scroll_arrow_light.png');
    }
    opacity: .5;
    transition: opacity .5s;

    @include size-tablet{
      visibility: hidden;
    };
  }

  &:hover{
    .scroll-arrow{
      opacity: 1;
    }
  }
}
