@import "_mixins.scss";

footer{
  //height: 75px;
  
  position: relative;
  padding-bottom: 35px;
  padding-top: 35px;

  @include size-phone{
    border-width: $mobile-border-width;
    padding-top: 20px;
    margin-bottom: 70px;

    &:after{
      display: block;
      content: " ";
      height: 20px;
      //background-color:#f00;
    }
  };

  .locations{
    //line-height: 75px;
    margin-top: 30px;

      @include size-phone{
          max-width: 70%;
          margin: auto;
      }
    
    &.offices{
      .location{
        font-weight: 700;
        color: $colour-primary;
      }
    }

    .location{
      display: inline-block;
      &:after{
        display: inline-block;
        content: '|';
        margin: -3px 9px 0;
      }
      &:last-of-type:after,&.last-in-line:after{
        display: none;
      }

      a{
        color: inherit;
      }
    }

    a{
      color:$colour-primary;
      &:hover{
        color:$colour-primary;
      }
    }

  }

  .legal-menu{
    @include size-phone{
     margin:20px auto;
     max-width: 90%;
    }
  }

  .address{
    display: flex;
    justify-content:  center;

    white-space: pre;
    @include size-phone{
      display: block;
    }
    .divider{
      &:before{
        content:'|';
        display: block;
        margin:0 10px;
         @include size-phone{
            display: none;
          }
      }
    }
  }

  .icons{
      position: relative;
      //height: 100%;
      //right: 22px;
      //top:0px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 15px;
      margin-bottom:20px;

      &.mobile{
        margin-top:15px;
        margin-bottom: 15px;
      }
      .icon{
        height: 23px;
        width: 23px;
        border-radius: 50%;
        background-color: $colour-primary;
        margin: 0 5px;

        background-repeat: no-repeat;
        background-position: center;
        background-size: 11px 11px;


        &.twitter{
          background-image: url('/assets/icons/twitter.svg');
        }
        &.facebook{
          background-image: url('/assets/icons/facebook.svg');
        }
        &.instagram{
          background-image: url('/assets/icons/instagram.svg');
        }
        &.linkedin{
          background-image: url('/assets/icons/linkedin.svg');
          background-size: 10px 10px;
          @include size-phone{
              //background-size: 14px 14px;
          }

        }
        &.email{
                    background-image: url('/assets/icons/email.svg');

        }
      }
  }
}
