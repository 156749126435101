.next-previous-project{

    display: flex;
    justify-content: space-between;

    padding:50px 0 80px;

	@include size-phone{
		padding:30px 0 60px;
	}

    a{
        display: block;


        &.next{
            display: flex;
            text-align:right;
            align-items: center;
            &:after{
                content:'>';
                display: block;
                margin-left: 10px;
            }
        }
        &.previous{
            display: flex;
            align-items: center;
            text-align:left;
            &:before{
                content:'<';
                display: block;
                margin-right: 10px;
                vertical-align: center;
            }
        }
    }

    
}