.divider-horizontal-line{
	
	@include dividingLine	

	position:relative;
	width:calc(60% - 10px); 
	margin-top: 65px;


	@include size-phone{
		margin-top: 20px;
	}
}