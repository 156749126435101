.quote-row{
		
	.name-title{
		margin-top: 30px;
	}
	.info{
		display: flex;
		justify-content: center;

		margin-top: 4px;

		@include size-phone{
			margin-top:2px;
		}

		.vertical-divider{
			display: inline-block;
			font-weight:200;
			margin: 0 13px;
		}


	}
	.inner-quote{
		&:before{
			@include dividingLine;
			position: relative;
			margin-top: 75px;
			margin-bottom: 50px;

			@include size-phone{
				margin-top: 25px;
				margin-bottom: 20px;
			}	
		}

		&:after{
			@include dividingLine;
			position: relative;
			margin-top: 50px;
			margin-bottom: 75px;

			@include size-phone{
				margin-top: 20px;
				margin-bottom: 25px;
			}
		}
	}
}