.hero{
	
	position: relative;



	margin-bottom: ($gutterWidth);

	border:0px solid #f00;

	padding-bottom:56.25%;

	@include size-phone{
		//padding-bottom:100%;
		//padding-bottom:165%;
		margin-bottom: ($mobileColumnDistance);
	}

	.video-player{
		height:100%;
		video{
			display: block;
		}

		.centred-containter{
			display:  none !important;
		}

		.controls{
			opacity: 1 !important;
		}

		.fullscreen-toggle{
			display: block;
			opacity: 0;
			transition: .5s;
			bottom:25px;
			right:25px;
			@include size-tablet-portrait{
				opacity: .5;

				bottom:10px;
				right: 10px;
			}


		}

		&:hover{
			.fullscreen-toggle{
				opacity: .5;
			}
		}
	}


	.background{
		position:absolute;
		top: 0;
		left:0;
		height: 100%;
		width: 100%;
		background-size: cover;
		background-position: 50% 50%;
		.background-video{
			height: 100%;
			//border:1px solid #f00;
			video{
				width:300%;
			}
		}


	}

	.hero-text{
		position:absolute;
		top: 0;
		left:0;
		height: 100%;
		width: 100%;

		display: flex;
		justify-content: center;
		align-items: center;




	}




}