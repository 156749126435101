.jobs-page{

    .job{
        margin-bottom: 60px;
    

        &:after{
            display: block;
            width: 60%;
            content: '';
            margin:40px auto 0;
            border-bottom: 1px solid $greyLineColor;
        }

        .job-description{
        
            h3,h4{
                font-family: 'plantin';
                font-weight: 600;
                font-size: 16px;
                text-transform: none;
                font-weight: bold;
                letter-spacing: 0.1px;
                margin: 40px auto 10px;
                &:after{
                    display: none;
                
                }
            }
        }
    }

    .apply-link{
        font-family: effra,sans-serif;
        font-weight: 900;
        font-size: 9px;
        letter-spacing: .12em;
        line-height: 17px;
        text-transform: uppercase;

        display: flex;
        justify-content: center;
        &::before{
            content: '<';
            display: inline-block;
            margin-right: 4px;
            white-space: nowrap;

        }

        &::after{
            content: '>';
            display: inline-block;
            margin-left: 4px;
            white-space: nowrap;

        }

        a{
            display: inline-block;
            white-space: nowrap;
            max-width: 0px;
            opacity: 0;
            overflow: hidden;

            //transition: max-width 1s, opacity .5s 0.7s;

            animation-name: applyLinkAnimation;
            animation-duration: 1.2s;
            animation-iteration-count: 1;
            animation-delay: .5s;
            animation-fill-mode: both;
          
        }
        /*
        &:hover{
            a{
                max-width: 500px;
                opacity: 1;
            }
        }*/

    }

    li{

        font-weight: normal;
    }


    @keyframes applyLinkAnimation {
          0% {
            max-width: 0px;
            opacity: 0;
          }
          75%{
            max-width: 200px;
            opacity: 0;
          }
          100%{
            opacity: 1;
            max-width: 200px;
          }
    }

}