.page-intro-copy{
		margin: 110px auto 90px;

		img{
			width: 100%;
			height: auto;
			max-width: 1000px;
		}


		&--short{
			margin: 60px auto 80px;
		}
	}