.contact-page{

    .contacts{
        display: flex;

        justify-content: stretch;
        flex-flow: wrap;

        margin: 50px 0;

        @include size-phone{
            margin:0 0 50px 0;
        }
        .contact{
            flex:1;
            flex-basis: 50%;
            position: relative;

            @include size-phone{
                flex-basis: 100%;
            }

            h3{
                &:after{
                    display: none;
                }
                margin-bottom: 30px;
            }

            a{
                &:hover{
                    .email{
                        color:$colour-primary;
                    }
                }
            }

            &:after{
                display: block;
                content:'';
                padding-bottom: 100%;

                @include size-phone{
                    padding-bottom: 70%;
                }

            }

            .inner{

                position: absolute;
                top:0%;
                left:0;
                height: 100%;
                width: 100%;
                display: flex;
                align-items: center;
                flex-flow: column;
                justify-content: center;
            }
            &:nth-child(even){
                &:before{
                    display: block;
                    content:'';
                    height: 100%;
                    width:1px;
                    background-color: $colour-primary;
                    position: absolute;
                    left:0;
                    top:0;


                    @include size-phone{
                        height: 1px;
                        width:100%;
                    }

                }
            }
        }
    }
}