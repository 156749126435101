@import "mixins.scss";
.video-player{

  position: relative;
  width: 100%;
  //height: 100vh;
  overflow: hidden;

  background:$colour-primary;

  @include size-phone{
     height: 100vh;
  }

  &:before{
    display: block;
    content:'';
    padding-bottom: 56.25%;

  }


  .vail{
    display: none;
    position: absolute;
    left:0;
    top:0;
    width: 100%;
    height: 100%;
    //background:$colour-primary;
    background:#020c36;
    background:#00143c;
    opacity: 0;
    transition: opacity .6s;
  }

  &.paused{
    .vail{
      opacity: .65;
    }
  }

  &.played{
    .vail{
      display: block;
    }
  }

  video{
    display: none;
    position: absolute;

    left:50%;
    top:50%;
    transform: translate(-50%,-50%);

    background-size:cover;

    @media (max-aspect-ratio: 16/9){
        height: 100%;
        width: auto;
    }

    @media (min-aspect-ratio: 16/9){
      height: auto;
      width: 100%;
    }


  


  }

  .vimeo-container{
    display: block;
    position: absolute;

    left:50%;
    top:50%;
    transform: translate(-50%,-50%);
    height: 100%;
    width: 100%;
      //for vimeo
      iframe{
        height: 100%;
        width:100%;
      }
  }

  &.keep-aspect{
    height: auto;
    video{
      position: relative;
      left:auto;
      top:auto;
      transform:none;
      width: 100%;
      height: auto;
    }
  }

  .video-poster{
    position: absolute;

    left:50%;
    top:50%;
    transform: translate(-50%,-50%);

    width: calc(100% + 2px);
    height: calc(100% + 2px);
    background-size:cover;
    background-position: center;
  }

  &.playing,&.played{
    video{
      display: block;
    }
      .video-poster{
          display: none;
      }
  }

  &.fullscreen{
    video{
      width: 100%;
      height: auto;
    }
    .FullScreen{
      background: #000; // $colour-primary;
    }
  }



  .controls{
    position: absolute;
    top:0px;
    left:0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    transition: .5s;

    &.hide{
      opacity: 0;
      cursor:none;
    }

    .centred-containter{
      //position: absolute;
      position: relative;

      font-size: 40px;
      color: #fff;

        .play-btn{

          height: 126px;
          width: 126px;
          border: 1px solid rgba(255,255,255,0.5);
          border-radius: 50%;
          line-height: 126px;
          //cursor: pointer;





          .play-text,  .pause-text{
            position: absolute;
            top:50%;
            left:50%;
            transform: translate(-50%,-50%);
            opacity: 0.5;
            pointer-events: none;
          }
          &:hover{
            .play-text,  .pause-text{
              opacity: 1;
            }
          }

          &.paused{
            .play-text{
              visibility: visible;
            }
            .pause-text{
              visibility: hidden;
            }
          }

          &.playing{
            .play-text{
              visibility: hidden;
            }
            .pause-text{
              visibility: visible;
            }
          }

          .inner-button{
            width: 60%;
            height: 60%;
            background: #00f;
            position: absolute;
            left:50%;
            top:50%;
            border-radius: 50%;
            transform: translate(-50%,-50%);
            opacity: 0;
            cursor: pointer;
          }

        }

        .drag-time{
          width: 120%;
          height: 120%;
          border-radius: 50%;
          position: absolute;
          top:50%;
          left:50%;
          transform: translate(-50%,-50%);
          background: #f00;
          opacity: 0;
        }



      .current-time{
        position: absolute;
        top:50%;
        //width: 200px;
        left: -10px;
        transform:translate(-100%,-50%);
        text-align: right;
        opacity: .5;
      }
      .total-time{
        position: absolute;
        right: -10px;
        top:50%;
        transform:translate(100%,-50%);
        text-align: left;
        opacity: .5;
      }

      .progress-circle{
        position: absolute;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%) rotate(-90deg);
        pointer-events: none;
        width: 130px;
        height: 130px;
        opacity: .5;
        circle{
          stroke-dasharray: 1000;
	        stroke-dashoffset: 1000;
          transition: all .05s linear;
        }
      }

    }




    .screen-controls{

    }

  }

  &.show-unmute-button{
    
    .controls{
      .current-time,.total-time,.play-text,.pause-text{
        opacity: 0 !important;
        visibility: hidden !important;
      }
    }

    .unmute-button{
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%);
      
      opacity: 0.5;
      pointer-events: all;

      background-image: url('/assets/video/sound-muted.svg');
      background-repeat: no-repeat;
      background-position:center;

      z-index: 10;

      &:hover{
        //border:1px solid #f00;
      }
    }
  }

  .fullscreen-toggle{
    display: none;
    position: absolute;
    right:70px;
    bottom:35px;
    width: 32px;
    height: 20px;
    background-image: url('/assets/video/fullscreen.svg');
    background-repeat: no-repeat;
    background-position:center;
    cursor: pointer;
    opacity: .5;
    &:hover{
      opacity: 1;
    }

    @include size-phone{
      right: 35px;
    };
  }
  &.played{
      .fullscreen-toggle{
        display: block;
      }
  }
  &.fullscreen{
    .fullscreen-toggle{
      background-image: url('/assets/video/fullscreen-mini.svg');
    }
  }


  // Show controls when playing only
  &.played{
        .audio-controls{
          display: block;
        }
  }



  .audio-controls{
      display: none;
      position: absolute;
      left:70px;
      bottom:45px;
      width: 145px;
      opacity: 0.5;

      &:hover{
        opacity: 1;
      }
      &:before{
        position: absolute;
        width: 150px;
        height: 25px;
        left:50%;
        top:0%;
        transform: translate(-50%,-30%);
        //border:1px solid #f00;
        content:' ';
      }

        @include size-phone{
          left: 35px;
        }


      .audio-off{
        position: absolute;
        left:0px;
        top:2px;
        height: 6px;
        width: 12px;
        background: url('/assets/video/sound-off.svg') no-repeat;

      }
      .audio-on{
        position: absolute;
        right:0px;
        top:0px;
        height: 12px;
        width: 12px;
        background: url('/assets/video/sound-on.svg') no-repeat;
      }

      .track{
        position: absolute;
        left:6px;
        top:5px;
        width: 125px;
        height: 1px;

        &:after{
          content: ' ';
          display: block;
          position: absolute;
          left: 50%;
          top:50%;
          width: calc(100% - 8px);
          height: 1px;
          background: #f00;
          transform:translate(-50%,-50%);
          background: #fff;
        }

        .dragger{
            position: absolute;
            //transform:translate(-50%,-50%);
            top:-4px;
            width: 10px;
            height: 10px;
            background: transparent;

            cursor: pointer !important;
            &:after{
              content: ' ';
              display: block;
              position: absolute;
              left: 50%;
              top:50%;
              width: 2px;
              height: 10px;
              background: #fff;
              transform:translate(-50%,-50%);
            }
        }
      }
  }

  &.not-played{

    .controls{
       .centred-containter{
         .play-btn {
           .pause-text,.play-text{
              opacity: 1 !important;
            }
          }

          .total-time,.current-time,.progress-circle{
            opacity: 1;
          }
        }
      }

  }



}

.ios,.android{
  .video-player{
      &.not-played{
        .controls{
          .centred-containter{
            .total-time,.current-time,.progress-circle{
              opacity: 0;
            }
          }
        }

      }

      .audio-controls {
        display: none;
        .track {
          //.dragger{
              display: none;
          //}
        }
      }

      .fullscreen-toggle{
        //display: none;
      }
    }
}

.ios{
  .fullscreen-toggle{
    display: none !important;
  }
}
