.people-page{
	//min-height:calc(100vh + 100px);
	//

	.people-small-wrapper{
		min-height: 600px;
	}
	.people{
		display: flex;
		margin:-$columDistance calc(#{-$columDistance / 2});
		justify-content: center;
		flex-wrap: wrap;

		//border:1px solid #f00;
		transition: all .5s;

		overflow-x:hidden;




		&.small{
			margin-top:calc(#{$columDistance});
			&:last-of-type{
				//min-height:600px;
				
				
			}
			&:first-of-type{

				margin-top:#{-$columDistance};
				@include size-tablet-portrait{
					margin-top:0;
				}
				
			}
				.person{
					.info{
						//max-height: none !important;
					}
				}
			}

		@include size-tablet-portrait{
			margin-top:0;
		}

		@include size-tablet-portrait{
			&.large{
				.person{
					min-width: 50%;
				}
			}
		}

		@include size-phone{

			margin:-$mobileColumnDistance calc(#{-$mobileColumnDistance / 2});

			justify-content: flex-start;

			&.large{
				.person{
					min-width: 100%;
				}
			}

			.person{
				min-width: 50%;
			}


		}

	}

	.info{
		max-height: 0;
		//overflow-y: hidden;
		opacity: 0;
		transition: max-height 1s .5s, min-height 1s .5s, opacity .5s; 
		position: relative;

	}

	.person.hide-overflow{
		.info{

			overflow: hidden;
		}
	}

	.person.open{
		.info{
			//max-height: 500px;
			opacity: 1;
			overflow: visible;
			transition: max-height 1s, min-height 1s, opacity .5s .5s; 

		}

		.photo{
			&:after,&:before{
				opacity: 0;
			}
		}

	}







	.person{

		cursor: pointer;;

		padding:calc(#{$columDistance / 2});

		//overflow-x:hidden;

		@include size-phone{
			
					padding:calc(#{$mobileColumnDistance / 2});
		}



		.photo{
			padding-bottom: 100%;
			background-size: cover;
			background-position:50% 50%;
			position:relative;


			@include blueOverlay;

			
		}

		&:hover{
			.photo{
			&:after,&:before{
					opacity: 0;
				}
			}
		}

		.info{
			//overflow: hidden;
			@include size-phone{
				//overflow: hidden;
			}
		}

		.text-container{
			padding-top:48px;
			
			margin: 0 -30px;
			margin:0;

			padding-bottom:30px;
			position: absolute;
			left:50%;
			top:0;
			width: 100%;
			transform: translate(-50%,0);

			@include size-phone{
				margin: 0;
				padding-top:20px;
				padding-bottom:50px;

			}

			.bio{
				padding-bottom:120px;

				@include size-tablet-portrait{
					padding-bottom:60px;
				}
			}
		}

		.title{
			//margin-top: 48px; 
		}
		h3{
			text-transform: none;
			display: block;

			//margin-left: -100px;
			//margin-right: -100px;

			@include size-phone{
				margin-left: 0px;
				margin-right: 0px;
			}
			//border:1px solid #f00;
		}

		.name-wrapper{
			//border:1px solid #f00;
			position:relative;
			margin-left:-30px;
			margin-right: -30px;
		}

		.bio{
			    width: 75%;
   				margin: auto;
	    	p{
	    		    text-align: justify;

	    	}

	    	//margin-bottom: 150px;
		}

		max-width:800px;
		min-width:123px;

	}


	.greetings{
		.greeting{
			position:absolute;
			top:50%;
			left:50%;
			transform: translate(-50%,-50%);

			opacity: 0;
			transition: opacity .5s;

			&.active{
				opacity: 1;
				transition: opacity .5s .5s;
			}
		}
	}
}