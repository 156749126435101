.related-case-studies{

	.related-case-studies-row{
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		h4,h3{
				&:after{
					display: none;
				}
			}

		&:last-of-type{
		
			border:0px solid #f00;

			h4,h3{
				
			}
			.related-case-study{
				&:after{
						display: none;
					}
			}
		
		}
	}

	.related-case-study{
		width: 33.3333%;

		//display: inline-block;

		position:relative;

		h4{
			//min-height: 90px;
			//margin-bottom: 60px;
		}

		&:after{
	      display: block;
	      position:absolute;
	      content: '';
	      height:1px;
	      width:50px;
	      background: #b2b2b2;

	      left:50%;
	      transform:translate(-50%,-50%);
	    }

	    &:after{
	      bottom:65px;
	    }


		@include size-tablet-portrait{
			width: 50%;
		}


		min-height: 200px;
	}

	padding:180px 0 80px;

	@include size-phone{
		padding:70px 0;
	}


	

}