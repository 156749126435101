.page-fade-enter {
  opacity: 0;
  z-index: 2;
  position:fixed;
  top:0px;
  left:0;
  height: 60%;
  width: 100%;

  @include size-phone{
    top:91px;
  }
}
.page-fade-enter-active {
  opacity: 1;
    transition: opacity 1s;

   //display: none;

}
.page-fade-exit {
  //position:relative;
  //top:0;
  //left:0;
  //opacity: 1;
  //z-index: 1;

  display: none;

}
.page-fade-exit-active {
  //opacity: 0;
  //transition: opacity 5s;

}