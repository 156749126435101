@import "_mixins.scss";

header{


  position: fixed;
  //position: sticky;
  margin-top:0;
  left: 0px;
  top:0px;

  width: 100%;
  height: 92px;
  background: $colour-background;
  z-index: 9999;
  transition: top 1s;
  will-change: top;
  /*
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;*/



  .divider{
    position: absolute;
    background: $colour-primary;
    width: 1px;
    height: 43px;
    left:50%;
    top:50%;
    transform: translate(0%,-50%);
  }

  .background{
    background: $colour-background;
    position: absolute;
    width: 100%;
    height: calc(100%);

  }

  &.show{
    top:0px;
  }

  &.hide{
    top:-92px;
    //transition: top 1s;
  }

  &.absolute{
    //position: absolute;
    transition: none !important;
    //top:0px !important
    //position: absolute;
    //position: sticky;
    //margin-top:92px;
    //top:0px;
    transition: top .1s !important;
    &.show{
      //border:0px solid #f00;
      //top:-92px !important;
      //padding-top: 92px;
    }
  }
  &.intro-animation{
    transition: none !important;
  }

  .logo{
    //background-image: url('/assets/global/logo.svg');
    position:absolute;
    left:calc(50% + 24px);
    top:35px;
    width: 122px;
    height: 18px;
    img{
      position: absolute;
      width: 100%;
      height: auto;
      left:0px;
      top:0px;
    }

    filter:blur(0px);



    img{
      transition: all .3s;
      opacity:0;
    }
    .k{
      opacity: 1 !important;
      transition: none;
    }


    .a{
      transition-delay: .3s;
    }
    .s{
      transition-delay: .25s;
    }
    .t{
      transition-delay: .20s;
    }
    .n{
      transition-delay: .15s;
    }
    .e{
      transition-delay: .1s;
    }
    .r{
      transition-delay: .05s;
    }

    &:hover{
      img{
        opacity: 1;
        @include size-tablet{
          opacity: 0;
        }
      }


      .a{
        transition-delay: .1s;
      }
      .s{
        transition-delay: .2s;
      }
      .t{
        transition-delay: .3s;
      }
      .n{
        transition-delay: .4s;
      }
      .e{
        transition-delay: .5s;
      }
      .r{
        transition-delay: .6s;
      }
    }
  }

}

.head-spacer{

  transition: height 1s;
  height: 0px;
  //background: #f00;
  &.show{
    height: calc(92px - #{$border-width/2});
  }
}

.force-hide-header{
  header{
    //display: none;
    top:-92px;
    //transition: none;
  }
  &.force-hide-header-overide{
    header{
      display: block;
      //top:0px;
      transition: none;
    }
  }
}

.force-show-header{
  header{
    top:0px !important;

  }
}

.intro-force-hide-header{
  header{
    //display: none;
    top:-92px;
    transition: none;
  }
}
