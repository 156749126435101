.case-study-intro{


	.intro-text{
		height: calc(100%);
		width: 100%;
		position:absolute;
		top:0;
		left:0;
		display: flex;
		align-items: center;
		.text-area{
			padding-bottom: 130px;
		}

		@include size-phone{
			position:relative;
			.text-area{
				padding-bottom: 50px;
			}
		}
	}

	.intro-image{
		background-size: cover;
		background-position: 50% 50%;
		position:absolute;
		width: 100%;
		height: 100%;
		left:50%;
		top:50%;

		transform: translate(-50%,-50%);
	}

	.scroll-arrow{
		position:absolute;
		left:calc(50% - 18px);

		@include size-phone{
			    bottom: 18px;
		}
	}
}