@import url("https://use.typekit.net/rds0ibm.css");
@import "_mixins.scss";




@mixin linkText{

  font-family: "effra",sans-serif;
  font-weight: 900;
  font-size: 9px;
  letter-spacing: 0.12em;
  line-height: 17px;
  text-transform: uppercase;

}

@mixin headlineLines{

    display: block;
    position: relative;

    &:after,&:before{
      display: block;
      position:absolute;
      content: '';
      height:3px;
      width:50px;
      background: $colour-white;
      left:50%;
      transform:translate(-50%,-50%);
    }

    &:before{
      top:-18px;
    }
    &:after{
      bottom:-20px;
    }

    @include size-tablet-portrait{
      &:after,&:before{
          height: 1px;
          width: 30px;
      }


      &:before{
        top:-8px;
      }
      &:after{
        bottom:-10px;
      }
    }

    &.no-line{
     
      &:after,&:before{
        display: none;
      }
    }

}


body{
  color:$colour-text;
  font-family: 'plantin';
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;

  -webkit-text-size-adjust:100%;

}

h1,h2,h3,h4{
  font-family: "effra",sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  //letter-spacing: -0.05em;

  .no-upper-case{
    text-transform:none;
  }
}



h2{
      font-size: 35px;
      font-size:32px;
      //line-height: 42px;
      line-height: 1.2;
      letter-spacing: .13em;

      //color:$colour-white;

      @include headlineLines;

      &.grey-line{

        &:before,&:after{
          background:#ccc;
          height: 2px;
          line-color:$greyLineColor;
        }
      }

      @include size-tablet-portrait{
        font-size: 20px;
        line-height: 1.2;
      }
}

h3,h4{

  font-size: 32px;
  line-height: 30px;
  line-height: 1.2;
  font-weight: 200;
  letter-spacing: 1px;
  margin-bottom:45px;
  margin-top: 15px;

  display: block;
  position:relative;

  @include headlineLines;

  &:before{
    display: none;
  }  

  &:after{
    background:#b2b2b7;
    height: 1px;
  }

  &.red-line{
     &:after{
       background:$colour-secondary;
     }
  }

     @include size-tablet-portrait{
        font-size: 20px;
        line-height: 1.2;
      }



}

h5{
    font-family: 'plantin';
    margin: 15px 0;
    text-align: left;
    font-size: 13px;
    line-height: 18px;

}

ul{
  padding-left:0px;
  margin: 15px 0 30px;
  margin-left: 0;
  display: block;

  li{
      text-align: left;
      font-weight:bold;
      margin-left: -3px;
      display: block;
      padding-left:15px;
      position:relative;

      &:before{
        display: block;
        position:absolute;
        content: '\2022';
        left:4px;
        top:1px;
      }
    }
}





body{
  font-size: 27px;
  font-size: 20px;
  font-size: 13px;
  line-height: 18px;
  p{
    line-height: inherit;
    text-align: left;
  }

 

  @include size-phone{
    font-size: 12px;
    line-height: 16px;
    p{
      font-size: 12px;
      line-height: 16px;
    }
  };
}
a{
  color: $colour-text;
  text-decoration: none;

  &.cta-link{
  //@include headlineLines;
      margin-top: 45px;
      display: block;
      font-family: effra,sans-serif;
      font-weight: 900;
      font-size: 11px;
      letter-spacing: .12em;
      line-height: 17px;
    text-transform: uppercase;
        color: $colour-secondary;
        height: 35px;
        line-height: 35px;
      &:before,&:after{
        display: inline-block;
        //background:$colour-secondary;
        //height: 1px;

      }
       &:before{
        //top:0px;
        content:'<';
        margin-right: 0.2em;
       }
       &:after{
        //bottom:0px
        content:'>';
          margin-left: 0.2em;
       }
  }
}


.large-text{
  p,li{
    font-size: 23px;
    line-height: calc(32 / 26);

    @include size-phone{
      font-size: 14px;
      //line-height: 22px;
    }

  }

  p{

    text-align: center;
  }
}



.red-text{
    color:$colour-secondary;
}

.text-area{

 

  @include size-tablet-portrait{
      max-width: 400px;
      max-width: 80%;
      margin-left:auto;
      margin-right: auto;
  }
}

.small{
  .person{
    h3{
      font-size: 24px;
    }
  }
}



// Menu

.main-menu{
  li{
    text-align: center;
    a{
      font-family: "effra",sans-serif;
      font-weight: 400;
      font-size: 24px;
      letter-spacing: .11em;
      text-transform: uppercase;
      //letter-spacing: -0.05em;
      transition: color .2s;
      color: $colour-secondary-text;

      @include size-tablet{
        font-size: 18px;
      };

      @include size-phone{
        font-size: 16px;
      };

      &:hover{
        color: $colour-secondary;
      }
    }
  }
  .language-selector{
    font-family: "effra",sans-serif;
    //font-weight: 300;
    font-size: 8px;
    letter-spacing: 0.12em;
    text-transform: uppercase;

    @include size-phone{
      font-size: 8px;
    };
    .current{
        font-weight: 600;
    }

  }
}


.link-labels{
  font-family: "effra",sans-serif;
  font-weight: 200;
  font-size: 24px;
  letter-spacing: .11em;
  text-transform: uppercase;
  @include size-tablet{
    font-size: 18px;
  };

  @include size-phone{
    font-size: 16px;
  };
}




.hero{
   h2{


      color:$colour-white;



   }
}

.case-study-preview{
  h3{
      //font-size: 35px;
      //line-height: 42px;
      letter-spacing: .13em;
      font-weight: 500;

      //margin: 0;

      color:$colour-white;

      @include headlineLines;

         @include size-tablet-portrait{
        font-size: 20px;
        line-height: 1.2;
        margin-bottom: 10px;
        margin-top:0;
      }
  }
}

.quote-row{
  .quote{
    font-style: italic;
    font-size: 35px;
    line-height: 45px;

    @include size-phone{
      font-size: 18px;
      line-height: 22px;
    }

    &:before{
      display: inline-block;
      content: '“'
    }
    &:after{
      display: inline-block;
      content: '”'
    }


  }
  .info,.name{
      font-family: "effra",sans-serif;
      text-transform: uppercase;
      font-weight: 300;
      letter-spacing: 1px;
    }
    .name{
      font-weight: 500;
    }
}





// Footer

footer{
  line-height: 20px;


  .address{
      color: $colour-primary;
      a{
           color: inherit;
      }
    }

  .locations,.legal-pop-up-link{
    font-size:9px;
    line-height: 20px;
    font-family:  "effra";
    //font-weight: 700;
    letter-spacing: .08em;
    //color: $colour-primary;
    text-transform: uppercase;
    //cursor: pointer;
    a{
      color: inherit;
    }



    @include size-phone{
      font-size: 8px;
      line-height: 15px;
    };
  }
  .legal-link{
    line-height: 20px;
  }
  .legal-pop-up-link{
    font-weight: normal;
  }

  .legal-link::after{
        font-size:9px;
        color: $colour-primary;
  }
}

.video-player{

    .play-text,.pause-text{
      font-size: 18px;
      letter-spacing: .200em;
      font-family: "effra",sans-serif;
      font-weight: 500;

      text-transform: uppercase;
    }

    .total-time,.current-time{
      font-size: 31px;
      font-family: "effra",sans-serif;
      font-weight: 300;
    }

}
