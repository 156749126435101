.text-row{
		.text{
			margin-top: 90px;
			margin-bottom:90px;

			@include size-phone{
				margin-top: 30px;
				margin-bottom: 30px;
			}
		}
}