



@import "_mixins";
@import "_grid";
@import "_header";
@import "_menu";
@import "_typeography";
@import "layout";


@import "hero";
@import "background-video";

@import "services-page";
@import "contact-page.scss";
@import "jobs-page.scss";
@import "case-study-preview";
@import "case-study-intro";
@import "image-row";
@import "related-case-study";
@import "quote-row";
@import "text-row";
@import "_scroll-arrow";
@import "divider-horizontal-line";
@import "page-intro-copy";
@import "people-page";
@import "footer";

@import "next-previous-project.scss";

@import "_video-player";

@import "page-transitions";


*{
	box-sizing:border-box;
}


html,body{
	height: 100%;
	min-height: 100%;
	position: relative;

	margin:0;

 overscroll-behavior: none;
 background:$colour-background;

}

body{
	&.no-scroll{
		.page-wrapper{
			overflow: hidden;
			position:absolute;
			height: 100%;
			width: 100%;
		}

	}
}

.page-wrapper{
	//position:absolute;
	//height: 100%;
	//width: 100%;
	//overflow: auto;
	//   overscroll-behavior:touch
	min-height: 100vh;
	//padding-top: 67px;

	@include size-tablet-portrait{
		padding-top:91px;
	}
}

.loading{
	img{
		position:fixed;
		top:50%;
		left:50%;
		width: 200px;
		height: auto;
		transform: translate(-50%,-50%);
	}
}