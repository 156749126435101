@import "_mixins";

// this is in the header

.menu-burger-holder{
  position: absolute;
  left:calc(50% - 53px);
  top:32px;
  width:35px;
  height:28px
}

.menu-burger{

  position: absolute;
  left:calc(50% - 48px);
  top:37px;

  top:5px;
  left:5px;

  width:25px;
  height:18px;
  //border: 1px solid #f00;
  overflow: hidden;

  cursor: pointer;


  &.open{
    transform: rotate(-180deg);
    top:35px;
    top:3px;

  }

  transition: transform .2s , top .2s;


  .line1,.line2,.line3{
    position: absolute;
    height: 2px;
    width:15px;
    left:5px;
    //transform: translateY(-50%);
    //border-radius:4px;
    background: $colour-primary;
  }



  .line1{
    top:0;
    transform-origin: right bottom;
  }
  .line2{
    top:7px;
    transform-origin: left bottom;
  }
  .line3{
    top:14px;
  }

  .arrow{
    position: absolute;
    //left:50%;
    //top:50%;
    left: 9%;
    top:0%;
    width: 19px;
    //height: 100%;
    //transform: translate(-50%,-50%);
    opacity: 0;
    transition: none;
    img{
      width: 100%;
      display: block;
    }
    *{
      transition: none;
    }
  }

  .arrow-left{
    //display: none;
    position: absolute;
    width: 50%;
    left:0px;
    top:0px;
    height: 100%;
    //background: rgba(0,0,255,.3);
    overflow: hidden;
    .inner-arrow{
      left: auto;
      left:100%;
      //background: #0f0;
    }
  }

  .arrow-right{
    //  display: none;
    position: absolute;
    width: 50%;
    right:0px;
    top:0px;
    height: 100%;
    //background: rgba(255,0,0,.3);
    overflow: hidden;
    .inner-arrow{
      left:0%;
      //background: #f00;
    }
  }

  .inner-arrow{
    position: absolute;
    height: 1px;
    width: 22px;
    left:50%;
    transform: translate(-50%,-50%);
    border-radius:4px;
    background: $colour-primary;
    top:30px;
    top:62%;
  }

}

.main-menu{


  position: fixed;
  top:0px;
  right:0px;
  width: 100%;
  height: calc(100vh);
  //height: 100%;
  //z-index: 0;
  background: $colour-background;
  padding-top:92px;

  transition: all .5s;

  overflow: auto;

  display: flex;
  flex-flow: column;

  .menu-outer{
    //border: 1px solid;
    flex:1;
    display:flex;
    align-items:center;
    justify-content: center;
    //  border-top:$border-width solid #fff;

      @include size-phone{
        ///border-width: $mobile-border-width;
      };

  }

  li,ul{
    list-style: none;
    padding: 0;
    margin: 0;

  }

  ul{
    padding: 0px 40px;
    display: block;
    list-style: none;
    margin-bottom: 90px;
    @include size-phone{
      margin-top: 15px;
    };
  }

  li{
    &:before{
      display: none;
    }

    &:last-of-type{
      .line{
        display: none;
      }
    }

    a{
      display: inline-block;
      position: relative;
      margin-top: 21px;

      @include size-tablet{
        margin-top: 12px;
      };
      @include size-phone{
        margin-top: 10px;
      };


   
    }

    .line{
        display: block;
        content:' ';
        position:relative;
        margin: 17px auto 0;
        background: $colour-secondary-text;
        opacity: .5;
        bottom:0px;
        width: 30px;
        height: 1px;

        @include size-tablet{
          margin-top: 12px;
        };




      }

      @include size-phone{
          margin-top: 0px;
            .line{
                margin-top: 10px;
                width: 31px;
            }
      };

  }


  &.closed{
    right:0vw;
    height: 0;
    padding: 0;
  }


  .language-selector{

      .list{
        //position: absolute;
        //bottom:4px;
        //left:50%;
        //transform: translateX(-50%);
        position: relative;
        min-height: 100px;
        padding-top: 20px;
        //border: 1px solid #0f0;
        @include size-phone{
          min-height: 80px;
        };

        display: flex;
        align-items: flex-start;
        justify-content: center;
        &:after{
          display: block;
          content: ' ';
          position: absolute;
          top:60px;
          width: 30px;
          height: 1px;
          left: 50%;
          margin-left: -15px;
          background: $colour-secondary-text;
          opacity: .5;
        }
        .label{
          margin: 0 5px 0 0;
          padding: 0 10px 0 5px;
          position: relative;
          text-align: center;
          cursor:pointer;
          &:after{
            content: " ";
            display: block;
            position: absolute;
            right: 0px;
            top:50%;
            transform: translateY(-50%);
            height: 10px;
            width: 1px;
            margin-left: 5px;
            margin-top: 0px;
            font-weight: 400;
            background: $colour-secondary-text;
            opacity: .5;
            text-align: center;
            vertical-align: baseline;
          }

          &:last-of-type{
            &:after{
              display: none;
            }
          }
        }
      }

  }


}
